<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="isShowAdd = true"
        >新建角色</el-button
      >
      <!-- 表格 -->
      <transition appear tag="div">
        <el-row>
          <el-col :span="4" v-if="isShowData">
            <el-table
              ref="multipleTable"
              :data="tableData"
              stripe
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column
                header-align="center"
                align="center"
                prop="id"
                label="编号"
                width="55"
              ></el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                prop="name"
                label="角色名称"
              >
                <template slot-scope="scope">
                  <div
                    class="btnshow"
                    @mouseover="mouseoverShow(scope.row.id)"
                    @mouseleave="mouseleaveShow"
                    @click.stop="nameClick(scope.row.id)"
                  >
                    <p
                      style="
                        cursor: pointer;
                        margin: 0;
                        padding: 12px 0;
                        box-sizing: border-box;
                      "
                      :class="active == scope.row.id ? 'active' : 1"
                    >
                      {{ scope.row.name }}
                    </p>
                    <div v-show="scope.row.id === isshows">
                      <el-button
                        type="text"
                        @click="isShoweditDialog(scope)"
                        size="mini"
                        >编辑</el-button
                      >
                      <el-button
                        type="text"
                        @click="delClick(scope.row.id)"
                        size="mini"
                        >删除</el-button
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <!-- 树形 -->
          <el-col :span="20" style="border: 1px solid #ebeef5">
            <el-tree
              :data="dataTree"
              show-checkbox
              node-key="id"
              ref="tree"
              :default-expanded-keys="[1]"
              :default-checked-keys="jurisdiction"
              :props="defaultProps"
            >
            </el-tree>
            <div style="margin: 10px">
              <el-button
                type="primary"
                size="small "
                icon="el-icon-plus"
                @click="addTree"
                >保存</el-button
              >
            </div>
          </el-col>
        </el-row>
      </transition>
      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        class="fenye"
        :current-page="query.current"
        :page-size="query.size"
        background
        small
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      title="添加角色"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="35%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input
            v-model="addForm.name"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑角色"
      :visible.sync="isShowEdit"
      width="35%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        ref="editRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input
            v-model="editForm.name"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="editClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  querySysRole,
  addSysRole,
  delSysRole,
  findResourceRecursion,
  sysRoleId,
  addSaveRoleResource,
} from '@/api/system.js'
export default {
  data() {
    return {
      isShowData: false,
      active: 1,
      isshows: undefined,
      isShowAdd: false,
      isShowEdit: false,
      query: {
        // 查询条件
        current: 1,
        size: 10,
      },
      total: undefined,
      tableData: [],
      addForm: {
        // 添加
        id: undefined,
        name: undefined,
      },
      editForm: {
        // 编辑
        id: undefined,
        name: undefined,
      },
      formRules: {
        // 添加表单的验证规则
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
      },
      dataTree: [], // 绑定权限数据
      defaultProps: {
        children: 'sunList',
        label: 'name',
      },
      jurisdiction: [], // 保存选中权限id
    }
  },
  created() {
    this.querySysRole()
  },
  methods: {
    querySysRole() {
      // 获取列表
      querySysRole(this.query).then((res) => {
        this.total = res.data.data.total
        this.active = res.data.data.list[0].id
        this.tableData = res.data.data.list
        this.findResourceRecursion()
        if (this.tableData) {
          this.isShowData = true
        }
      })
    },
    handleCurrentChange(val) {
      // 分页
      this.query.current = val
      this.querySysRole()
      console.log(`当前页: ${val}`)
    },
    addTree() {
      // 保存角色资源
      let allKeys = this.$refs.tree.getCheckedKeys(false) // 全部keys
      allKeys = this.$refs.tree.getHalfCheckedKeys().concat(allKeys)
      const leafKeys = this.$refs.tree.getCheckedKeys(true) // 叶子keys
      const noLeafKeys = allKeys.filter((item) => leafKeys.indexOf(item) === -1) // 非叶子keys
      const leafNodes = leafKeys.map((item) => {
        return {
          resourceId: item,
          isLeaf: 1,
        }
      })
      const noLeafNodes = noLeafKeys.map((item) => {
        return {
          resourceId: item,
          isLeaf: 0,
        }
      })
      const allNodes = leafNodes.concat(noLeafNodes)
      const queryObj = {
        id: this.active,
        sysRoleResourceList: allNodes,
      }
      addSaveRoleResource(queryObj).then((res) => {
        if (res.data.code === '200') {
          this.querySysRole()
          this.$message.success('保存权限成功')
        }
      })
    },
    mouseoverShow(id) {
      // 鼠标进入显示
      this.isshows = id
    },
    mouseleaveShow() {
      // 鼠标离开
      this.isshows = false
    },
    findResourceRecursion() {
      // 查询所有的权限列表
      findResourceRecursion().then((res) => {
        if (res.data.code === '200') {
          this.dataTree = res.data.data
          this.nameClick(this.active)
        }
      })
    },
    nameClick(id) {
      // 根据id获取当前用户的权限
      this.active = id
      sysRoleId(id).then((res) => {
        this.jurisdiction = res.data.data.sysRoleResourceList
          .filter((item) => item.isLeaf == 1)
          .map((item) => item.resourceId)
        this.$refs.tree.setCheckedKeys(this.jurisdiction)
      })
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    addClick() {
      // 添加
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        addSysRole(this.addForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowAdd = false
            this.$message.success('添加成功')
            this.querySysRole()
          }
        })
      })
    },
    isShoweditDialog(scope) {
      // 编辑显示弹框
      this.isShowEdit = true
      this.editForm = scope.row
    },
    editClick() {
      // 编辑
      addSysRole(this.editForm).then((res) => {
        if (res.data.code === '200') {
          this.isShowEdit = false
          this.$message.success('编辑成功')
          this.querySysRole()
        }
      })
    },
    delClick(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delSysRole(id).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              this.querySysRole()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.active {
  color: #409eff;
}
.el-card {
  position: relative;
  padding-bottom: 30px;
  .fenye {
    position: absolute;
    left: 20px;
    bottom: 10px;
  }
}
.btnshow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep .el-col-20 {
  margin-top: 46px;
}
::v-deep .el-table td {
  padding: 0;
}
</style>
